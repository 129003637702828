import React from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Switch, Redirect } from 'react-router-dom'
import {
  DOCUMENTS_ROUTE as BASE_PATH,
  DOCUMENTS_ENROLLMENT_ROUTE,
  DOCUMENTS_ELIGIBLES_ROUTE,
} from 'utils/constants/routes'
import { Page, SubNav } from '../../..'
import { EligiblePeople } from './EligiblePeople'
import { EnrollmentReports } from './EnrollmentReports'

const Documents = () => {
  const { t } = useTranslation()

  const SUBPATHS = [
    {
      path: DOCUMENTS_ENROLLMENT_ROUTE,
      displayName: t('documents_page.employee_enrollment_list'),
    },
    {
      path: DOCUMENTS_ELIGIBLES_ROUTE,
      displayName: t('documents_page.eligibles_list'),
    },
  ]

  return (
    <Page title={t('documents')} testId="documents-page">
      <SubNav paths={SUBPATHS} />

      <Switch>
        <Route path={DOCUMENTS_ENROLLMENT_ROUTE}>
          <EnrollmentReports />
        </Route>
        <Route path={DOCUMENTS_ELIGIBLES_ROUTE}>
          <EligiblePeople />
        </Route>
        <Redirect from={BASE_PATH} to={DOCUMENTS_ENROLLMENT_ROUTE} />
      </Switch>
    </Page>
  )
}

export { Documents }
