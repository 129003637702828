import { useDownloadEligibles, usePartnerByUser } from 'data/hooks'
import { format } from 'date-fns'
import { useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDownloadInBrowser } from 'ui/hooks'
import { RenderResponse, FlexRowWithCTA } from '../../..'
import { useAlerts, AlertVariants } from '../../../Alerts'

// ensure input wont cause problems on a filesystem
const sanitize = (input: string) => input.replace(/[<>:"/\\|?*]/g, '').trim()

// mirroring the BE filename conventions - we can't fetch the filename from the BE
// because the download is created upon request
const getFilename = (partnerName: string) => {
  const date = format(new Date(), 'MM-dd-yyyy')

  return `${date} ${sanitize(partnerName)}.csv`
}

const EligiblePeople = () => {
  const { t } = useTranslation()
  const { addAlert } = useAlerts()
  const { getEligibles, reset, loading, error, data } = useDownloadEligibles()

  const {
    data: partnerData,
    loading: partnerLoading,
    error: partnerError,
  } = usePartnerByUser()
  const partnerName = partnerData?.partnerByUser?.name || ''

  const { url } = data

  const filename = getFilename(partnerName)

  useDownloadInBrowser({
    url,
    error,
    filename,
  })

  const addErrorAlert = useCallback(() => {
    addAlert({
      variant: AlertVariants.error,
      message: t('documents_page.download_error', { name: filename }),
      autoClose: 8000,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const handleDownloadClick = () => {
    getEligibles()
      .catch(addErrorAlert)
      .finally(() => {
        setTimeout(reset, 0)
      })
  }

  useEffect(() => {
    if (error) {
      addErrorAlert()
    }
  }, [error, addErrorAlert])

  return (
    <RenderResponse {...{ loading: partnerLoading, error: partnerError }}>
      <FlexRowWithCTA
        id={filename}
        name={filename}
        handleClick={handleDownloadClick}
        isDisabled={loading}
        isLoading={loading}
        ctaText={t('documents_page.download')}
      />
    </RenderResponse>
  )
}

export { EligiblePeople }
