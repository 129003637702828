import { Factory } from 'fishery'
import {
  PartnerTransferUsers_partnerTransferUsers as TPartnerTransferUsers,
  PartnerTransferUsers_partnerTransferUsers_partner as TLimitedPartner,
  PartnerTransferUsers_partnerTransferUsers_transferUserKeys as TTransferUserKeys,
} from '../queries/types/PartnerTransferUsers'

const limitedPartnerFactory = Factory.define<TLimitedPartner>(({ sequence }) => ({
  __typename: 'LimitedTransferPartner',
  id: `${sequence}`,
}))

const transferUserKeyFactory = Factory.define<TTransferUserKeys>(({ sequence }) => ({
  __typename: 'TransferUserKey',
  id: `${sequence}`,
  sshPublicKeyBody: 'sftpkey',
}))

const partnerTransferUserFactory = Factory.define<TPartnerTransferUsers>(
  ({ sequence, associations }) => ({
    __typename: 'PartnerTransferUser',
    id: `${sequence}`,
    userName: `sftp-username-${sequence}`,
    notes: '',
    transferUserKeys:
      associations.transferUserKeys || transferUserKeyFactory.buildList(1),
    partner: limitedPartnerFactory.build(),
  }),
)

export { partnerTransferUserFactory, transferUserKeyFactory }
