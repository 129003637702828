import { black, red, yellow, white } from '@pelotoncycle/design-system'
import styled, { css } from 'styled-components'
import { media } from '../responsive'

export enum BannerVariants {
  error = 'error',
  warn = 'warn',
}

type TBannerProps = {
  variant?: BannerVariants
  message: React.ReactNode
  testId?: string
}

function getBannerStyles(bannerVariant: BannerVariants) {
  switch (bannerVariant) {
    case BannerVariants.warn:
      return css`
        color: ${black};
        background-color: ${yellow[80]};
      `
    case BannerVariants.error:
    default:
      return css`
        color: ${white};
        background-color: ${red[80]};
      `
  }
}

const Banner = ({ variant, message, testId }: TBannerProps) => (
  <StyledBanner data-testid={testId} variant={variant}>
    {/* comment */}
    {message}
  </StyledBanner>
)

const SubPageBanner = ({ variant, message, testId }: TBannerProps) => (
  <StyledSubPageBanner data-testid={testId} variant={variant}>
    {message}
  </StyledSubPageBanner>
)

const StyledBanner = styled.div<{ variant?: BannerVariants }>`
  padding: 8px 16px;
  line-height: 28px;
  text-align: left;
  ${({ variant }) => variant && getBannerStyles(variant)};

  ${media.atTablet`
    padding: 0;
    height: 40px;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    line-height: 40px;
  `};
`

const StyledSubPageBanner = styled.div<{ variant?: BannerVariants }>`
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 13px;
  border-radius: 3px;
  ${({ variant }) => variant && getBannerStyles(variant)};
`

export { Banner, SubPageBanner }
