import { REPORT_DOWNLOAD_PATH, REPORT_SUMMARY_DOWNLOAD_PATH } from 'utils/constants/api'
import { useDocumentFetcher } from './useDocumentFetcher'

const { REACT_APP_API_HOST = '' } = process.env

type TGetReportProps = {
  date: string
}

const useEnrollmentReport = () => {
  const URL = `${REACT_APP_API_HOST}${REPORT_DOWNLOAD_PATH}`
  const { fetchDocument, reset, loading, error, data } = useDocumentFetcher()

  const getReport = async (hashId: string, name: string) => {
    const url = `${URL}/${hashId}`
    await fetchDocument({ url, name: name || '' })
  }

  return { getReport, reset, loading, error, data }
}

const useEnrollmentReportSummary = () => {
  const URL = `${REACT_APP_API_HOST}${REPORT_SUMMARY_DOWNLOAD_PATH}`
  const { fetchDocument, reset, loading, error, data } = useDocumentFetcher()

  const getReport = async ({ date }: TGetReportProps) => {
    const reportType = 'EER'
    const queryParams = `date=${date}&reportType=${reportType}`
    const url = `${URL}?${queryParams}`
    await fetchDocument({ url })
  }

  return { getReport, reset, loading, error, data }
}

export { useEnrollmentReport, useEnrollmentReportSummary }
