import { gql } from '@apollo/client'

const CREATE_TRANSFER_USER_KEY = gql`
  mutation TransferUserKeyCreate($transferUserKeyInput: TransferUserKeyInput!) {
    transferUserKeyCreate(transferUserKeyInput: $transferUserKeyInput) {
      requestOk
      transferUserKey {
        id
        sshPublicKeyBody
      }
    }
  }
`

const DELETE_TRANSFER_USER_KEY = gql`
  mutation TransferUserKeyDelete($transferUserKeyId: ID!) {
    transferUserKeyDelete(transferUserKeyId: $transferUserKeyId) {
      requestOk
    }
  }
`

export { CREATE_TRANSFER_USER_KEY, DELETE_TRANSFER_USER_KEY }
