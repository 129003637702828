import { gql } from '@apollo/client'
import { PARTNER_TRANSFER_USER_FIELDS } from '../fragments'

const CREATE_PARTNER_TRANSFER_USER = gql`
  ${PARTNER_TRANSFER_USER_FIELDS}
  mutation PartnerTransferUserCreate(
    $partnerTransferUserInput: PartnerTransferUserInput!
  ) {
    partnerTransferUserCreate(partnerTransferUserInput: $partnerTransferUserInput) {
      requestOk
      partnerTransferUser {
        ...PartnerTransferUserFields
      }
    }
  }
`

const UPDATE_PARTNER_TRANSFER_USER = gql`
  ${PARTNER_TRANSFER_USER_FIELDS}
  mutation PartnerTransferUserUpdate(
    $partnerTransferUserId: ID!
    $partnerTransferUserUpdateInput: PartnerTransferUserUpdateInput!
  ) {
    partnerTransferUserUpdate(
      partnerTransferUserId: $partnerTransferUserId
      partnerTransferUserUpdateInput: $partnerTransferUserUpdateInput
    ) {
      requestOk
      partnerTransferUser {
        ...PartnerTransferUserFields
      }
    }
  }
`

const DELETE_PARTNER_TRANSFER_USER = gql`
  mutation PartnerTransferUserDelete($partnerTransferUserId: ID!) {
    partnerTransferUserDelete(partnerTransferUserId: $partnerTransferUserId) {
      requestOk
    }
  }
`

export {
  CREATE_PARTNER_TRANSFER_USER,
  UPDATE_PARTNER_TRANSFER_USER,
  DELETE_PARTNER_TRANSFER_USER,
}
