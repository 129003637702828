import { insightsSharedCopy } from 'ui/copy/insightsSharedCopy'

const translation = {
  actions: 'Actions',
  active_participants: 'Active Participants',
  activity_reports: 'Activity Reports',
  as_of_x_date: 'As of {{date}}',
  back_to_help_and_support: 'Back to Help & Support',
  close_alert: 'Close Alert',
  close_menu: 'Close Menu',
  countXErrors_one: '{{count}} error',
  countXErrors_other: '{{count}} errors',
  data_specification: 'Data Specification',
  dashboard: 'Dashboard',
  description: 'Description',
  details: 'Details',
  documents: 'Documents',
  download: 'Download',
  download_csv_template: 'Download CSV Template',
  download_templates: 'Download Templates',
  drop_or_click_to_select_file: 'Drop CSV file or click to select file',
  effective_and_termination_dates: 'Effective & Termination Dates',
  email: 'Email',
  enrollment: 'enrollment',
  example: 'Example',
  faqs: 'FAQs',
  field_name: 'Field Name',
  file_format: 'File Format',
  file_submission_cadence: 'File Submission Cadence',
  get_started_eligibility: 'Getting started with eligibility',
  help_and_support: 'Help & Support',
  invoices: 'Invoices',
  loading: 'Loading',
  navigation_menu: 'Navigation Menu',
  no_files_prompt: 'Get started by uploading a file',
  no_results: 'No results',
  not_found_main: 'Oops, that page could not be found.',
  open_menu: 'Open Menu',
  optional: 'Optional',
  optional_with_disclaimer: 'Optional (required to end an eligible person’s benefit)',
  overview: 'Overview',
  not_found_support:
    '<0>Go back</0> or reach out to your account manager for assistance.',
  recent_uploads: 'Recent Uploads',
  required_slash_optional: 'Required / Optional',
  required: 'Required',
  search: 'Search',
  search_uploaded_eligibles: 'Search uploaded eligibles',
  sort_by_options: 'Sort by options',
  status: 'Status',
  templates: 'Templates',
  total_eligibles: 'Total Eligibles',
  troubleshooting: 'Troubleshooting',
  upload_file: 'Upload File',
  uploads: 'Uploads',
  troubleshooting_contact_us: 'Please contact us at partnersupport@onepeloton.com.',
  downtime_page: {
    title: 'Our site is temporarily down for maintenance.',
    body: 'We are undergoing scheduled maintenance and will be back online shortly.',
  },
  alerts: {
    upload_failure:
      'Your file could not be uploaded. Please check for errors and try again.',
    upload_succeeded: 'Successfully uploaded!',
    upload_with_errors: 'Your CSV was uploaded with errors. Please check your file.',
    polling_error:
      'Something went wrong while retrieving updated file information. Please try refreshing the page.',
  },
  banners: {
    last_upload_failed:
      'Your last upload failed. Please see <0>details</0> for any errors and try again.',
  },
  eligibles_data: {
    benefit: 'BENEFIT',
    effective: 'EFFECTIVE',
    internal_id: 'INTERNAL ID',
    last_change: 'LAST CHANGE',
    termination: 'TERMINATION',
  },
  errors: {
    something_went_wrong: 'Something went wrong. Please refresh the page.',
    unexpected_error: 'An unexpected error occurred',
    logging_out: 'Logging out. Please sign back in to continue.',
  },
  file: {
    added: 'ADDED',
    dropped: 'DROPPED',
    errors: 'ERRORS',
    file_name: 'FILE NAME',
    title: 'TITLE',
    total: 'TOTAL',
    total_in_this_file: 'TOTAL IN THIS FILE',
    uploaded: 'UPLOADED',
    status: 'STATUS',
  },
  fatal_errors: {
    generic_message:
      'The following critical error {{name}} prevented your file from being processed.',
    missing_required_column:
      'The required column {{column}} is missing. Please ensure all required columns are filled out.',
    no_schema_found_for_partner:
      'Peloton account setup is incomplete. Please contact your account manager for help.',
    no_programs_found_for_partner: '$t(fatal_errors.no_schema_found_for_partner)',
    no_pipeline_found_for_partner: '$t(fatal_errors.no_schema_found_for_partner)',
    duplicate_column_values_on_file:
      'This file contains one or more rows with the same {{column, replaceUnderscores}}. Please ensure {{column, replaceUnderscores}} values are unique per employee.',
    new_internal_id_for_existing_email:
      "This file contains one or more rows with a different internal ID associated with a previously submitted eligible Member's email address. To resolve, please contact your account manager.",
    encryption: 'Encrypted files are not supported. Please upload an unencrypted file.',
    invalid_filetype:
      'Uploaded file is not in CSV format, please upload a valid CSV file',
    invalid_internal_id:
      'The internal ID on one or more rows is too long. Please ensure internal ID values do not exceed 255 character length.',
  },
  documents_page: {
    download: 'Download',
    employee_enrollment_list: 'Employee Enrollment List',
    instructions:
      "Click the download button for the month's report. After the file has been received, your browser will prompt you to save or open the file.",
    title: 'Title',
    download_error: 'Unable to download {{name}}',
    no_reports_prompt:
      'Employee enrollment lists are not currently available for your organization.',
    eligibles_list: 'Eligible Population',
  },
  row_errors: {
    generic_message:
      'The following error {{name}} occured when trying to process your file.',
    validation_error:
      'The information you provided in {{column}} is not in the correct format.',
    transform_failed: '$t(row_errors.validation_error)',
    missing_required_value_for_field:
      '{{column}} is required and missing relevant information.',
    term_date_before_effective_date:
      'Termination date cannot be earlier than effective date. Please ensure termination dates occur later than effective dates for these rows.',
    file_has_no_readable_contents:
      'Your file has no readable content, please upload a valid file',
  },
  dropzone: {
    default_upload_error: 'Something went wrong, please try another file',
    'too-many-files': 'Please upload one file at a time.',
    'file-invalid-type': 'File must be .csv',
    'file-too-large': 'File too large',
  },
  file_details: {
    last_attempt_x: 'Last attempt: {{date}}',
    critical_error: 'Critical Error: New Upload Required',
    rows_with_errors: 'Rows with errors ({{total}})',
    action_required: 'Action required',
    countXOccurrences_one: '{{count}} Occurrence',
    countXOccurrences_other: '{{count}} Occurrences',
    with_termination_date: '{{count}} with termination date',
    rows_one: 'Row: {{rows}}',
    rows_other: 'Rows: {{rows}}',
  },
  user_menu: {
    account_settings: 'ACCOUNT SETTINGS',
    sign_out: 'SIGN OUT',
    support: 'HELP & SUPPORT',
  },
  // namespace values we are getting from the API or schema-defined enums (i.e. keys we have no control over)
  api: {
    file_status: {
      running: 'Processing...',
      succeeded: 'Uploaded',
      failed: 'Failed',
      succeeded_with_errors: 'Errors found',
      rolled_back: 'Rolled Back',
    },
  },
  insights: {
    ...insightsSharedCopy,
  },
  benefit_admin: {
    benefit_admin: 'Benefit Admin',
    clear_list: 'CLEAR LIST',
    date_format: 'mm/dd/yyyy',
    date_updated: 'Date updated!',
    employee_email: 'EMPLOYEE EMAIL',
    error_loading_employee: 'Unable to load member. Try refreshing the page.',
    no_employees_selected: 'no employees selected',
    offboarding_date: 'OFFBOARDING DATE',
    remove_employee_benefit: 'remove employee benefit',
    remove_end_benefit_date: 'remove end benefit date',
    search_employees: 'Search employees',
    status: 'STATUS',
    term_date_status: {
      unscheduled: 'Offboarding date not scheduled',
      scheduled: 'Offboarding scheduled',
      error: 'Offboarding date could not be scheduled',
    },
    total_updated_one: "You've updated {{ count }} member",
    total_updated_other: "You've updated {{ count }} members",
    upload_eligibility_file: 'upload eligibility file',
    uploads_subnav: '',
    update_explanation:
      'Updates are saved automatically. Offboarding will begin on the selected date(s) and benefit(s) will be removed on or before the next billing cycle.',
  },
  support: {
    faqContent: {
      why_file_failed: {
        question: 'Why did my eligibility file fail to upload?',
        answer:
          'A file failure usually means your eligibility file is in an unrecognizable format or is missing critical required data, such as the name or email columns. Please compare your eligibility file to the specifications shared in <0>Getting Started with Eligibility</0> to ensure that it contains the correct information and has the correct header labels.',
      },
      file_format: {
        question: 'In what file format does my eligibility file need to be?',
        answer:
          'Eligibility files must be in .CSV format with column headers as specified in the Partner Eligibility file specification. You can also download the <0>sample template .csv</0> for additional reference.',
      },
      multiple_files: {
        question: 'Can I upload more than one eligibility file at the same time?',
        answer:
          'The Corporate Wellness Portal does not support multi-file uploads. Please upload one correctly-formatted eligibility file at a time, as this file will be used to confirm the eligibility of qualified individuals at your organization for Peloton Corporate Wellness. If you attempt to upload more than one file, you may receive an error notification indicating that too many files have been uploaded.',
      },
      upload_cadence: {
        question: 'How often do I need to upload an eligibility file?',
        answer:
          'A file should be uploaded whenever you would like to update Peloton with changes in your team’s eligibility for Peloton Corporate Wellness benefits. A minimum file submission cadence is not required.',
      },
      add_new_people: {
        question: 'When I upload an eligibility file, can I just add the new people?',
        answer:
          'To add new eligible individuals, you will need to upload a new eligibility file. You have the option: (1) to upload a full eligibility file, with the entire population of eligible individuals, including new people and those in a previously uploaded file, or (2) to upload a change eligibility file with just the subset of eligible individuals you would like to add to your organization’s Peloton Corporate Wellness benefit.',
      },
      how_to_remove: {
        question: 'How do I remove people from my organization’s eligibility file?',
        answer:
          'To remove any individual from your organization’s Peloton Corporate Wellness benefit, simply upload a new eligibility file with termination dates for those individuals. A person’s benefit will not renew after the termination date provided in your eligibility file; however, any person no longer eligible for your Peloton Corporate Wellness benefit will still be able to access their Membership until their next billing renewal date.',
      },
      other_issues_or_questions: {
        question: 'What happens if I have other issues or questions?',
        answer:
          'Please contact a member of your Peloton Corporate Wellness account team.',
      },
      termination_file_info: {
        question: 'What information will I need to include for a termination file?',
        answer:
          'The termination file requires that both email address and termination date be populated. Partner admin will have access to upload the termination file, note to confirm spelling on emails is correct.',
      },
      termination_file_use: {
        question:
          "Can I still use the termination file even if I don't use an eligibility file to upload members?",
        answer:
          'Yes, regardless of your onboarding flow, the admin will be able to end benefit for employees using the termination file via the customer portal.',
      },
      termination_file_past_date: {
        question: 'Can I set a termination date in the past?',
        answer: 'No, always use present and future dates.',
      },
      termination_file_format: {
        question: 'What is the  preferred format for entering a termination date?',
        answer: 'The recommended date format is YYYY-MM-DD',
      },
    },
    overview: [
      'The Peloton Corporate Wellness team aims to make enrollment into our benefits system as seamless as possible so that you and your employees can take full advantage of the Peloton Community!',
      'To support you in managing your employees’ eligibility for Peloton, our online portal is here for you to submit your organization’s eligibility data. As your employee team changes or grows, you have an easy, on-demand platform to update your team’s enrollment status in the Peloton Corporate Wellness Portal by using data easily exported from your internal system of record.',
      'Please refer to the following sections in order to get your eligibility data in the required format to upload to the Peloton Corporate Wellness Portal. Once you have successfully uploaded your data, you should plan on sending an updated version of your eligibility data at least once per month if any individuals lose eligibility or join your organization, but you may update your data as frequently as necessary.',
    ],
    file_format: {
      format_header: 'Eligibility files should be submitted in the following format:',
      csv: 'CSV (.csv)',
      format_instructions: [
        'Data content shall begin on row 1',
        'One eligible person per row',
        'Header row required',
      ],
    },
    submission_cadence: {
      no_min_cadence_required:
        'Although we suggest that you submit an eligibility file once a month, there is no minimum file submission cadence.  You may submit files as frequently as you would like; however, you can only submit one file at a time.',
      min_timing_for_processing:
        'You are responsible for submitting eligibility files at least three business days prior to the desired process date.',
      can_be_full_or_change_files:
        'Eligibility files can be full files (the entire population of eligible individuals at your organization) or change files (a subset of eligible individuals at your organization experiencing a change).',
      must_set_termination:
        'Note: To terminate eligibility for an eligible individual, you must explicitly set a valid termination_date for the given eligible individual in an eligibility file.',
    },
    effective_termination_dates: {
      eligibility_defined:
        'Benefit eligibility for an eligible individual is defined by setting effective (start) and termination (end) dates in the eligibility file.',
      effective_date:
        'Effective Date: Signifies the start date from which an eligible individual shall be eligible for benefits.',
      partner_go_live:
        'If applicable, your organization’s ‘go live’ date may be entered in this field.',
      go_live_example:
        'For example: Set this to the first date you want your eligible individuals to access this benefit. For any new hires, you can enter their hire date.',
      future_date: 'This field may also be set to a future date.',
      future_date_example:
        'For example: New hires to be eligible for benefits on their future hire dates.',
      default_to_current:
        'If not provided, <bold><i>effective_date</i></bold> will default to the current date and <bold>your employees will be immediately eligible for your Peloton benefit.</bold>',
      termination_date:
        'Termination Date: Signifies the end date of benefits for an eligible person.',
      leave_empty:
        'It is recommended you leave this field empty until a specific termination date is known.',
      current_employee_example:
        'For example: If an individual is currently employed with no known termination date, please leave this field blank.',
      resign_example:
        'For example: When an individual resigns from your organization, you would need to upload a new eligibility file with their last day of employment as the termination date to terminate their eligibility for your organization’s Peloton benefits.',
      no_longer_eligible_note:
        '<bold><i>Note: When an individual is no longer eligible for your organization’s Peloton benefits (for any reason, including termination or resignation), you shall inform Peloton by setting a valid termination_date for the person on a subsequent eligibility file upload.</i></bold>',
    },
    data_specification: {
      field_names: {
        internal_id: 'internal_id',
        first_name: 'first_name',
        last_name: 'last_name',
        email_address: 'email_address',
        effective_date: 'effective_date',
        termination_date: 'termination_date',
      },
      field_descriptions: {
        string: 'string;',
        string_alphanumeric: 'string; alphanumeric',
        string_date_format: 'string; YYYY-MM-DD',
        internal_id: 'Member unique static identification number¹',
        first_name: 'Member first name (Max: 255 characters)',
        last_name: 'Member last name (Max: 255 characters)',
        email_address: 'Member email address (Max: 254 characters)',
        effective_date: 'Start date of benefit eligibility',
        termination_date: 'End date of benefit eligibility² ',
      },
      examples: {
        internal_id: 'HUE7XJ87',
        first_name: 'Christopher',
        last_name: 'Johnson',
        email_address: 'christopher@email.com',
        effective_date: '2020-11-03',
        termination_date: '2022-07-04',
      },
      header_conflicts_note:
        'Note: Eligibility files submitted with data in excess or conflict of the supported headers and data types defined may be rejected or extraneous data may be ignored. Peloton Corporate Wellness does not permit the collection of personal information outside of the supported data fields defined in this specification.',
      data_disclaimer_1:
        '¹ If a static unique internal identifier cannot be produced, we recommend creating an internal_id field by combining several other static fields that will not change in order to uniquely identify each eligible employee. For example: LastName-BirthDate-StartDate. Do not use sensitive personal information (such as social security number or private medical identification number) for this field.',
      data_disclaimer_2:
        '² If termination date is not configured, you must explicitly terminate a member by setting a valid termination_date for the member on a subsequent eligibility file upload.',
    },
  },
}

export { translation }
