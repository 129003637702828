import { useUploadFile, useFiles, usePollForFile } from 'data/hooks'
import { FileStatus } from 'data/types/graphql-global-types'
import React, { useEffect, useCallback } from 'react'
import { FileRejection } from 'react-dropzone'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { get } from 'utils'
import { BENEFIT_ADMIN_UPLOADS_ROUTE } from 'utils/constants/routes'
import {
  AddOrDropFile,
  AlertVariants,
  EmptyState,
  RenderResponse,
  Table,
  useAlerts,
  Box,
  SubPageBanner,
  BannerVariants,
} from '../../..'
import { tableHeadings } from './headingData'

const FileList: React.FC<React.PropsWithChildren<Record<string, unknown>>> = () => {
  const { t } = useTranslation()
  const { addAlert, clearAlerts } = useAlerts()
  const { loading, error, data } = useFiles()

  const addSuccessAlert = useCallback(() => {
    addAlert({
      variant: AlertVariants.success,
      message: t('alerts.upload_succeeded'),
      autoClose: 8000,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addSuccessWithErrorsAlert = useCallback(() => {
    addAlert({
      variant: AlertVariants.info,
      message: t('alerts.upload_with_errors'),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // even an upload with status of 'FAILED' will return a 200, so this
  // callback should only run on server-level errors
  const onFailure = () => {
    addAlert({
      message: t('alerts.upload_failure'),
      variant: AlertVariants.error,
    })
  }

  const onPollingError = useCallback(() => {
    addAlert({
      variant: AlertVariants.info,
      message: t('alerts.polling_error'),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDropzoneRejection = (fileErrors: FileRejection[]) => {
    if (fileErrors && fileErrors.length) {
      const errorCode = get(fileErrors, 0, 'errors', 0, 'code')
      const message = t([`dropzone.${errorCode}`, 'dropzone.default_upload_error'])

      addAlert({
        message,
        variant: AlertVariants.error,
      })
    }
  }

  const {
    pollForFileId,
    isUploading,
    uploadStatus,
    error: pollingError,
  } = usePollForFile()

  const { uploadFile, newFileUploading } = useUploadFile({
    onSuccess: pollForFileId,
    onFailure,
    clearAlerts,
    writeToCache: true,
  })

  const fileData = get(data, 'files') || []
  const lastFile = get(fileData, 0)
  const lastFileStatus = lastFile?.status
  const lastUploadFailed = lastFileStatus === FileStatus.FAILED

  useEffect(() => {
    if (uploadStatus && uploadStatus === FileStatus.SUCCEEDED) {
      addSuccessAlert()
    } else if (uploadStatus && uploadStatus === FileStatus.SUCCEEDED_WITH_ERRORS) {
      addSuccessWithErrorsAlert()
    }
  }, [uploadStatus, addSuccessAlert, addSuccessWithErrorsAlert])

  useEffect(() => {
    if (pollingError) onPollingError()
  }, [pollingError, onPollingError])

  return (
    <div data-testid="file-list-page">
      <AddOrDropFile
        disabled={isUploading}
        onDrop={uploadFile}
        onDropRejected={handleDropzoneRejection}
        accept=".csv"
      >
        {t('drop_or_click_to_select_file')}
      </AddOrDropFile>

      {lastUploadFailed && (
        <Box marginTop="8px">
          <SubPageBanner
            variant={BannerVariants.error}
            message={
              <Trans
                i18nKey="banners.last_upload_failed"
                components={[
                  <StyledLink
                    key="details-link"
                    to={`${BENEFIT_ADMIN_UPLOADS_ROUTE}/${lastFile.id}`}
                  >
                    {t('details')}
                  </StyledLink>,
                ]}
              />
            }
            testId="banner-last-upload-failed"
          />
        </Box>
      )}
      <RenderResponse {...{ loading, error }}>
        {fileData.length ? (
          <Table
            headings={tableHeadings}
            rowData={fileData}
            newRowLoading={newFileUploading}
          />
        ) : (
          <EmptyState message={t('no_files_prompt')} />
        )}
      </RenderResponse>
    </div>
  )
}

const StyledLink = styled(Link)`
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
`

export { FileList }
