import { gql } from '@apollo/client'

const TRANSFER_USER_KEY_FIELDS = gql`
  fragment TransferUserKeyFields on TransferUserKey {
    id
    sshPublicKeyBody
  }
`

const PARTNER_TRANSFER_USER_FIELDS = gql`
  ${TRANSFER_USER_KEY_FIELDS}
  fragment PartnerTransferUserFields on PartnerTransferUser {
    id
    userName
    notes
    partner {
      id
    }
    transferUserKeys {
      ...TransferUserKeyFields
    }
  }
`

export { PARTNER_TRANSFER_USER_FIELDS, TRANSFER_USER_KEY_FIELDS }
