import { Button, white } from '@pelotoncycle/design-system'
import styled from 'styled-components'
import { Box } from './Box'
import { Flex } from './Flex'

type FlexRowProps = {
  name: string
  children?: React.ReactNode
  headingLevel?: number
}

type FlexRowWithCTAProps = {
  id: string
  name: string
  handleClick: () => void
  isDisabled: boolean
  isLoading: boolean
  ctaText: string
}

const FlexRow = ({ children, name, headingLevel = 2 }: FlexRowProps) => {
  const Heading = `h${headingLevel}` as keyof JSX.IntrinsicElements

  return (
    <Flex
      backgroundColor={white}
      alignItems="center"
      justifyContent="space-between"
      marginBottom="16px"
      key={name}
      padding="0 16px"
      height="64px"
    >
      <StyledHeading as={Heading}>{name}</StyledHeading>

      {children}
    </Flex>
  )
}

const FlexRowWithCTA = ({
  id,
  name,
  handleClick,
  isDisabled,
  isLoading,
  ctaText,
}: FlexRowWithCTAProps) => {
  return (
    <FlexRow name={name}>
      <Box width="128px">
        <Button
          size="small"
          width="adaptive"
          data-testid={`download-btn-${id}`}
          aria-label={ctaText}
          isDisabled={isDisabled}
          isLoading={isLoading}
          onClick={handleClick}
        >
          {ctaText}
        </Button>
      </Box>
    </FlexRow>
  )
}

const StyledHeading = styled.h2`
  margin-right: 80px;
`

export { FlexRowWithCTA }
