/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Audiences {
  corporate = 'corporate',
  partnership = 'partnership',
  university = 'university',
}

export enum DateFormat {
  DDMMYYYY_DASHES = 'DDMMYYYY_DASHES',
  DDMMYYYY_SLASHES = 'DDMMYYYY_SLASHES',
  MMDDYYYY_DASHES = 'MMDDYYYY_DASHES',
  MMDDYYYY_SLASHES = 'MMDDYYYY_SLASHES',
  NONE = 'NONE',
  YYYYMMDD_DASHES = 'YYYYMMDD_DASHES',
  YYYYMMDD_SLASHES = 'YYYYMMDD_SLASHES',
}

export enum EligiblePersonSearchFields {
  EMAIL = 'EMAIL',
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
}

export enum FileRunStatus {
  APPLYING_ELIGIBILITY = 'APPLYING_ELIGIBILITY',
  APPLY_ELIGIBILITY_FAILED = 'APPLY_ELIGIBILITY_FAILED',
  CALCULATE_ELIGIBILITY_FAILED = 'CALCULATE_ELIGIBILITY_FAILED',
  CALCULATING_ELIGIBILITY = 'CALCULATING_ELIGIBILITY',
  NO_FILE = 'NO_FILE',
  RECEIVE_FAILED = 'RECEIVE_FAILED',
  RECEIVING = 'RECEIVING',
  ROLLED_BACK = 'ROLLED_BACK',
  ROLL_BACK_FAILED = 'ROLL_BACK_FAILED',
  STAGE_FAILED = 'STAGE_FAILED',
  STAGING = 'STAGING',
  SUCCESS = 'SUCCESS',
  TRANSFORMING = 'TRANSFORMING',
  TRANSFORM_FAILED = 'TRANSFORM_FAILED',
  VALIDATING = 'VALIDATING',
  VALIDATION_FAILED = 'VALIDATION_FAILED',
}

export enum FileStatus {
  FAILED = 'FAILED',
  ROLLED_BACK = 'ROLLED_BACK',
  RUNNING = 'RUNNING',
  SUCCEEDED = 'SUCCEEDED',
  SUCCEEDED_WITH_ERRORS = 'SUCCEEDED_WITH_ERRORS',
}

export enum PartnerOrderBy {
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
}

export enum ProgramBenefitCountry {
  AU = 'AU',
  CA = 'CA',
  DE = 'DE',
  GB = 'GB',
  US = 'US',
}

export enum RecipientType {
  employee = 'employee',
  member = 'member',
}

export enum ReportReportType {
  CUSTOM_EEL = 'CUSTOM_EEL',
  EER = 'EER',
  INSIGHTS = 'INSIGHTS',
}

export enum ReportTypes {
  CUSTOM_EEL = 'CUSTOM_EEL',
  EER = 'EER',
  INSIGHTS = 'INSIGHTS',
}

export interface EligiblePersonInput {
  emailAddress?: string | null
}

export interface EligiblePersonUpdateDataInput {
  terminationDate?: string | null
}

export interface PartnerContactInput {
  firstName?: string | null
  lastName?: string | null
  emailAddress?: string | null
  sftpNotification?: boolean | null
}

export interface PartnerInput {
  name?: string | null
  partnerType?: string | null
  salesforceCustomerId?: string | null
  slug?: string | null
  domains?: string[] | null
  dateFormat?: DateFormat | null
  terminationDate?: any | null
  overrideWelcomeEmail?: boolean | null
  overrideReminderEmail?: boolean | null
  welcomeEmailDate?: any | null
  reminderEmailDays?: number | null
  welcomeEmailTemplateId?: string | null
  reminderEmailTemplateId?: string | null
  defaultProgram?: string | null
  enrollmentOptimization?: boolean | null
  logo?: string | null
  recipientType?: RecipientType | null
}

export interface PartnerTransferUserInput {
  partnerId: string
  userName: string
  notes?: string | null
}

export interface PartnerTransferUserUpdateInput {
  notes: string
}

export interface ProgramBenefitInput {
  benefitId?: string | null
  country?: ProgramBenefitCountry | null
  eligibleForActivePay?: boolean | null
  couponCode?: string | null
  partnerPaysRaw?: number | null
  userPaysRaw?: number | null
  discountRaw?: number | null
}

export interface ProgramInput {
  name?: string | null
  partnerHashId?: string | null
  monthsInactiveUntilSnooze?: number | null
}

export interface TransferUserKeyInput {
  transferUserId: string
  sshPublicKeyBody: string
}

//==============================================================
// END Enums and Input Objects
//==============================================================
