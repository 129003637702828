import React from 'react'
import { useRestHeaders } from './useRestHeaders'
import type { AddFileFunc } from './useUploadFile'

type TCallback = () => void

type UseUploadLogoProps = {
  partnerId: string
  onSuccess?: TCallback
  onFailure?: TCallback
}

const { REACT_APP_API_HOST = '' } = process.env

const UPLOAD_REST_ENDPOINT = `${REACT_APP_API_HOST}/api/logo-upload`

const useUploadLogo = ({ onSuccess, onFailure, partnerId }: UseUploadLogoProps) => {
  const { getHeaders } = useRestHeaders()
  const [requestError, setRequestError] = React.useState<string | null>(null)
  const [newFileUploading, setNewFileUploading] = React.useState(false)

  // TS wants a synchronous function passed to Dropzone's onDrop, hence all the .then chaining
  const uploadFile: AddFileFunc = (acceptedFiles = [], rejectedFiles = []) => {
    if (rejectedFiles.length) {
      return null
    }

    if (acceptedFiles.length) {
      const [file] = acceptedFiles

      if (file) {
        setNewFileUploading(true)
        const formData = new FormData()
        formData.append('logo', file)
        formData.append('partner_id', partnerId)

        const requestOptions = {
          method: 'POST',
          body: formData,
          headers: {},
        }

        getHeaders()
          .then(hdrs => {
            requestOptions.headers = hdrs

            fetch(UPLOAD_REST_ENDPOINT, requestOptions)
              .then(response => {
                const { ok, statusText } = response
                if (ok) {
                  return response.json()
                }
                throw new Error(statusText)
              })
              .then(() => {
                if (onSuccess) onSuccess()
                setNewFileUploading(false)
              })
              .catch(err => {
                setNewFileUploading(false)
                if (typeof err === 'string') {
                  setRequestError(err)
                } else if (err instanceof Error && err?.message) {
                  setRequestError(err.message)
                }
                if (onFailure) onFailure()
              })
          })
          .catch(e => {
            /* eslint-disable-next-line no-console */
            console.log(e)
          })
      }
    }

    return null
  }

  return {
    uploadFile,
    error: requestError,
    newFileUploading,
  }
}

export { useUploadLogo }
