import { useEnrollmentReports, useEnrollmentReport } from 'data/hooks'
import { useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDownloadInBrowser } from 'ui/hooks'
import { FlexRowWithCTA, RenderResponse, EmptyState } from '../../..'
import { useAlerts, AlertVariants } from '../../../Alerts'

const FILE_EXTENSION = '.csv'

const EnrollmentReports = () => {
  const { t } = useTranslation()
  const { addAlert } = useAlerts()
  const { loading, error, data } = useEnrollmentReports()
  const {
    getReport,
    reset,
    loading: reportLoading,
    error: reportError,
    data: report,
  } = useEnrollmentReport()

  const reports = data?.reports || []
  const hasReports = reports && !!reports.length
  // report comes from context, not directly from apollo cache
  const reportURL = report?.url
  const reportName = report?.name
  const reportFilename = reportName || 'report'
  const filenameWithExt = `${reportFilename}${FILE_EXTENSION}`

  useDownloadInBrowser({ url: reportURL, error: reportError, filename: filenameWithExt })

  const addErrorAlert = useCallback(() => {
    addAlert({
      variant: AlertVariants.error,
      message: t('documents_page.download_error', { name: filenameWithExt }),
      autoClose: 8000,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportError])

  const handleDownloadClick = (hashId: string, name: string) => {
    getReport(hashId, name)
      .catch(addErrorAlert)
      .finally(() => {
        setTimeout(reset, 0)
      })
  }

  useEffect(() => {
    if (reportError) {
      addErrorAlert()
    }
  }, [reportError, addErrorAlert])

  return (
    <RenderResponse {...{ loading, error }}>
      {hasReports ? (
        <>
          {reports.map(({ hashId, filename }) => {
            return (
              <FlexRowWithCTA
                key={hashId}
                id={hashId}
                name={`${filename}${FILE_EXTENSION}`}
                handleClick={() => handleDownloadClick(hashId, filename)}
                isDisabled={reportLoading}
                isLoading={reportName === filename}
                ctaText={t('documents_page.download')}
              />
            )
          })}
        </>
      ) : (
        <EmptyState message={t('documents_page.no_reports_prompt')} />
      )}
    </RenderResponse>
  )
}

export { EnrollmentReports }
