import { gql } from '@apollo/client'
import { PARTNER_TRANSFER_USER_FIELDS } from '../fragments'

const GET_PARTNER_TRANSFER_USERS = gql`
  ${PARTNER_TRANSFER_USER_FIELDS}
  query PartnerTransferUsers($partnerId: ID) {
    partnerTransferUsers(partnerId: $partnerId) {
      ...PartnerTransferUserFields
    }
  }
`

export { GET_PARTNER_TRANSFER_USERS }
