import { useReducer, useCallback } from 'react'
import { useRestHeaders } from './useRestHeaders'

type TState = {
  loading: boolean
  error: string | null
  reportName: string | null
  reportURL: string | null
}

enum ActionType {
  SetData,
  SetError,
  Reset,
}

type TAction = {
  type: ActionType
  payload?: Partial<TState>
}

const initialState = {
  loading: false,
  error: null,
  reportName: null,
  reportURL: null,
}

function reducer(state: TState, action: TAction): TState {
  switch (action.type) {
    case ActionType.SetData:
      return {
        ...state,
        ...action.payload,
      }
    case ActionType.SetError:
      return {
        ...state,
        loading: false,
        error: action.payload?.error as string,
      }
    case ActionType.Reset:
    default:
      return initialState
  }
}

type FetchDocumentProps = {
  url: string
  name?: string
}

const useDocumentFetcher = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { loading, error, reportName, reportURL } = state
  const { getHeaders } = useRestHeaders()

  const fetchDocument = async ({ url, name = '' }: FetchDocumentProps) => {
    const headers = await getHeaders()
    const requestOptions = {
      method: 'GET',
      headers,
    }

    dispatch({
      type: ActionType.SetData,
      payload: { reportName: name, loading: true },
    })

    try {
      const response = await fetch(url, requestOptions)
      if (!response.ok) throw new Error(`${response.status} - ${response.statusText}`)
      const blob = await response.blob()
      const objectURL = URL.createObjectURL(blob)

      dispatch({
        type: ActionType.SetData,
        payload: { reportURL: objectURL },
      })
    } catch (err) {
      let errorMessage = 'An error occured while downloading report.'
      if (err instanceof Error) {
        errorMessage = err.message
      } else if (typeof err === 'string') {
        errorMessage = err
      }

      dispatch({
        type: ActionType.SetError,
        payload: {
          error: errorMessage,
        },
      })
    }
  }

  const reset = useCallback(() => dispatch({ type: ActionType.Reset }), [])

  const data = { name: reportName, url: reportURL }

  return { fetchDocument, reset, loading, error, data }
}

export { useDocumentFetcher }
