import { gql } from '@apollo/client'

const EMAIL_TEMPLATE_FIELDS = gql`
  fragment EmailTemplateFields on EmailTemplate {
    id
    name
  }
`
const PARTNERS_FIELDS = gql`
  fragment PartnersFields on Partner {
    id
    hashId
    name
    salesforceCustomerId
    slug
    partnerType
  }
`
const PARTNER_FIELDS = gql`
  fragment PartnerFields on Partner {
    id
    hashId
    name
    salesforceCustomerId
    slug
    partnerType
    dateFormat
    domains
    domainOnboarding
    welcomeEmailDate
    overrideWelcomeEmail
    overrideReminderEmail
    reminderEmailDays
    terminationDate
    logo
    enrollmentOptimization
  }
`

const PROGRAM_BENEFIT_FIELDS = gql`
  fragment ProgramBenefitFields on ProgramBenefit {
    id
    country
    subscriptionType
    userPaysRaw
    partnerPaysRaw
    discountRaw
    currency
    eligibleForActivePay
    benefit {
      id
      name
      subscriptionType
      retailRates {
        country
        retailRateInCents
        currency
      }
    }
    program {
      id
      monthsInactiveUntilSnooze
    }
  }
`

const PROGRAM_FIELDS = gql`
  ${PROGRAM_BENEFIT_FIELDS}
  fragment ProgramFields on Program {
    id
    name
    slug
    partnerHashId
    monthsInactiveUntilSnooze
    programBenefits {
      ...ProgramBenefitFields
    }
  }
`

const CONTACT_FIELDS = gql`
  fragment ContactFields on Contact {
    id
    firstName
    lastName
    emailAddress
    sftpNotification
  }
`

const PARTNER_WITH_CONTACTS_FIELDS = gql`
  ${PARTNER_FIELDS}
  ${CONTACT_FIELDS}
  ${PROGRAM_FIELDS}
  ${EMAIL_TEMPLATE_FIELDS}
  fragment PartnerWithContactFields on Partner {
    ...PartnerFields
    program {
      ...ProgramFields
    }
    contacts {
      ...ContactFields
    }
    welcomeEmailTemplate {
      ...EmailTemplateFields
    }
    reminderEmailTemplate {
      ...EmailTemplateFields
    }
  }
`

export { PARTNERS_FIELDS, PARTNER_FIELDS, PARTNER_WITH_CONTACTS_FIELDS, CONTACT_FIELDS }
