import { ELIGIBLE_PEOPLE_DOWNLOAD_PATH } from 'utils/constants/api'
import { useDocumentFetcher } from './useDocumentFetcher'

const { REACT_APP_API_HOST = '' } = process.env

const useDownloadEligibles = () => {
  const url = `${REACT_APP_API_HOST}${ELIGIBLE_PEOPLE_DOWNLOAD_PATH}`

  const { fetchDocument, reset, loading, error, data } = useDocumentFetcher()

  const getEligibles = async () => {
    await fetchDocument({ url })
  }

  return { getEligibles, reset, loading, error, data }
}

export { useDownloadEligibles }
